import {
  Breadcrumb,
  Button,
  Card,
  Form,
  Icon,
  message,
  Spin,
  Table,
} from "antd"
import { gql } from "apollo-boost"
import { FieldArray, Formik } from "formik"
import { Link } from "gatsby"
import moment from "moment"
import qs from "query-string"
import React from "react"
import { Mutation, Query } from "react-apollo"
import * as yup from "yup"
import {
  DatePicker,
  FormItem,
  Input,
  InputNumber,
  Select,
} from "../../components/antd-formik"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

const { Option } = Select
const { Column } = Table

const formItemLayoutWithLabel = {
  labelCol: { xs: { span: 24 }, sm: { span: 8 } },
  wrapperCol: { xs: { span: 24 }, sm: { span: 16 } },
}

const formItemLayoutWithoutLabel = {
  wrapperCol: {
    xs: { span: 24, offset: 0 },
    sm: { span: 16, offset: 8 },
  },
}

const UPDATE_STOCK_OUT = gql`
  mutation updateStockOut(
    $id: bigint
    $customer_id: Int
    $marketplace_id: Int
    $date: date
    $no_doc: String
    $details: [mbt_stock_out_details_insert_input!]!
    $item_ids: [Int!]
  ) {
    delete_mbt_stock_out_details(
      where: {
        _and: [{ stock_out_id: { _eq: $id } }, { item_id: { _nin: $item_ids } }]
      }
    ) {
      affected_rows
    }
    insert_mbt_stock_outs(
      objects: [
        {
          id: $id
          customer_id: $customer_id
          marketplace_id: $marketplace_id
          date: $date
          no_doc: $no_doc
          details: {
            data: $details
            on_conflict: {
              constraint: stock_out_details_pkey
              update_columns: [quantity]
            }
          }
        }
      ]
      on_conflict: {
        constraint: stock_out_pkey
        update_columns: [customer_id, marketplace_id, date, no_doc]
      }
    ) {
      affected_rows
    }
  }
`

const LIST_AUTO_COMPLETE = gql`
  query listAutoComplete {
    mbt_customers(order_by: { name: asc }) {
      id
      name
    }
    mbt_items(order_by: { name: asc }) {
      id
      name
    }
    mbt_marketplaces(order_by: { name: asc }) {
      id
      name
    }
  }
`

const initialDetail = { item_id: "", quantity: 0 }
const stockOutSchema = yup.object({
  customer_id: yup
    .string()
    .label("Customer")
    .required(),
  marketplace_id: yup
    .string()
    .label("Marketplace")
    .required(),
  date: yup
    .date()
    .label("Date")
    .required(),
  no_doc: yup
    .string()
    .label("No doc")
    .required(),
  details: yup.array(
    yup
      .object({
        item_id: yup
          .string()
          .label("Item")
          .required(),
        quantity: yup
          .number()
          .typeError("Quantity is an invalid number")
          .label("Quantity")
          .min(1)
          .required(),
      })
      .required()
  ),
})

const StockOutForm = ({ stockOut = {}, loading: queryLoading }) => (
  <Mutation mutation={UPDATE_STOCK_OUT}>
    {(updateStockOut, { loading }) => {
      const disabled = queryLoading || loading
      return (
        <Query fetchPolicy="cache-and-network" query={LIST_AUTO_COMPLETE}>
          {({ data, loading: queryLoading }) => (
            <Formik
              enableReinitialize
              initialValues={{
                id: stockOut.id,
                customer_id: stockOut.customer_id,
                marketplace_id: stockOut.marketplace_id,
                date: stockOut.date || moment().toISOString(),
                no_doc: stockOut.no_doc,
                details: (stockOut.details || [initialDetail]).map(detail => ({
                  item_id: detail.item_id,
                  quantity: detail.quantity,
                })),
              }}
              validationSchema={stockOutSchema}
              onSubmit={async (variables, { resetForm }) => {
                const details = variables.details.reduce((details, detail) => {
                  details[detail.item_id] = detail
                  return details
                }, {})
                const item_ids = stockOut.details
                  .filter(({ item_id }) => Boolean(details[item_id]))
                  .map(({ item_id }) => item_id)

                try {
                  await updateStockOut({
                    variables: { ...variables, item_ids },
                  })
                  resetForm(variables)
                  message.success("Successfully edited stock out")
                } catch (err) {
                  message.error("Failed to edit stock out")
                }
              }}
            >
              {({ dirty, handleSubmit, handleReset, values }) => (
                <Form
                  {...formItemLayoutWithLabel}
                  onReset={handleReset}
                  onSubmit={handleSubmit}
                >
                  <FormItem name="customer_id" label="Customer">
                    <Select
                      disabled={disabled}
                      name="customer_id"
                      notFoundContent={
                        queryLoading ? <Spin size="small" /> : null
                      }
                      optionFilterProp="children"
                      showSearch
                    >
                      {(data.mbt_customers || []).map(({ id, name }) => (
                        <Option key={id} value={id}>
                          {name}
                        </Option>
                      ))}
                    </Select>
                  </FormItem>
                  <FormItem name="marketplace_id" label="Marketplace">
                    <Select
                      disabled={disabled}
                      name="marketplace_id"
                      notFoundContent={
                        queryLoading ? <Spin size="small" /> : null
                      }
                      optionFilterProp="children"
                      showSearch
                    >
                      {(data.mbt_marketplaces || []).map(({ id, name }) => (
                        <Option key={id} value={id}>
                          {name}
                        </Option>
                      ))}
                    </Select>
                  </FormItem>
                  <FormItem name="date" label="Date">
                    <DatePicker name="date" disabled={disabled} format="L" />
                  </FormItem>
                  <FormItem name="no_doc" label="No doc">
                    <Input disabled={disabled} name="no_doc" />
                  </FormItem>
                  <Form.Item {...formItemLayoutWithoutLabel}>
                    <FieldArray
                      name="details"
                      render={({ push, remove }) => (
                        <Table
                          bordered
                          dataSource={values.details}
                          pagination={false}
                          rowKey={(record, index) => index}
                          size="small"
                          title={() => (
                            <Button
                              type="primary"
                              icon="plus"
                              disabled={disabled}
                              onClick={() => push(initialDetail)}
                            >
                              Add
                            </Button>
                          )}
                        >
                          <Column
                            title="Item"
                            key="item_id"
                            width="60%"
                            render={(text, record, index) => (
                              <FormItem name={`details.${index}.item_id`}>
                                <Select
                                  disabled={disabled}
                                  name={`details.${index}.item_id`}
                                  notFoundContent={
                                    queryLoading ? <Spin size="small" /> : null
                                  }
                                  optionFilterProp="children"
                                  showSearch
                                >
                                  {(data.mbt_items || []).map(
                                    ({ id, name }) => (
                                      <Option key={id} value={id}>
                                        {name}
                                      </Option>
                                    )
                                  )}
                                </Select>
                              </FormItem>
                            )}
                          />
                          <Column
                            title="Quantity"
                            key="quantity"
                            width="40%"
                            render={(text, record, index) => (
                              <FormItem name={`details.${index}.quantity`}>
                                <InputNumber
                                  disabled={disabled}
                                  name={`details.${index}.quantity`}
                                  style={{ width: "100%" }}
                                  min={0}
                                />
                              </FormItem>
                            )}
                          />
                          <Column
                            title="Action"
                            key="action"
                            render={(text, record, index) => (
                              <Button
                                type="danger"
                                icon="close"
                                disabled={
                                  loading || values.details.length === 1
                                }
                                onClick={() => remove(index)}
                              />
                            )}
                          />
                        </Table>
                      )}
                    />
                  </Form.Item>
                  <Form.Item {...formItemLayoutWithoutLabel}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      disabled={!dirty}
                      loading={loading}
                    >
                      Save
                    </Button>{" "}
                    <Button htmlType="reset" disabled={loading || !dirty}>
                      Reset
                    </Button>
                  </Form.Item>
                </Form>
              )}
            </Formik>
          )}
        </Query>
      )
    }}
  </Mutation>
)

const GET_STOCK_OUT = gql`
  query getStockOut($id: bigint!) {
    mbt_stock_outs_by_pk(id: $id) {
      id
      customer_id
      marketplace_id
      date
      no_doc
      details {
        item_id
        quantity
      }
    }
  }
`

export default ({ location }) => {
  const { id } = qs.parse(location.search)
  return (
    <Layout>
      <SEO title="Stock Out" />
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>
          <Link to="/">
            <Icon type="home" />
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to="/stock-out/">Stock Out</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Edit</Breadcrumb.Item>
      </Breadcrumb>
      <Query
        fetchPolicy="cache-and-network"
        query={GET_STOCK_OUT}
        variables={{ id }}
      >
        {({ data, loading }) => (
          <Card>
            <StockOutForm
              stockOut={data.mbt_stock_outs_by_pk}
              loading={loading}
            />
          </Card>
        )}
      </Query>
    </Layout>
  )
}
